import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useResponsive from "../hooks/useResponsive"
import useTranslation from "../hooks/useTranslation"

// Logos
import ResponsiveIcon from "../assets/icons/responsive.svg"
import BubblesIcon from "../assets/icons/bubbles.svg"
import OneTouchIcon from "../assets/icons/one-click.svg"
import ReloadIcon from "../assets/icons/reload.svg"
import SafeIcon from "../assets/icons/safe.svg"
import PaintIcon from "../assets/icons/paint.svg"

// Components
import PhoneDevice from "../components/phone-device"
import SectionTitle from "../components/section-title"
import Chart from "../components/chart"
import GetStartedSection from "../components/get-started-section"

// Styles
import "../styles/pages/mobile-payments-service-provider.scss"

const HeroSection = ({ imagesFluid }) => {
  const { t } = useTranslation()
  return (
    <section className="section is-hero has-margin-bottom-6">
      <div className="container">
        <div className="columns ">
          <div className="column is-5">
            <h1 className="title has-text-white is-spaced main-title ">
              {t("Mobile Payments Made Easy")}
            </h1>
            <h2 className="subtitle main-subtitle has-text-white has-margin-bottom-6">
              {t(
                "Streamline your checkout process with a payment solution that renders beautifully on every device. Twispay provides a highly customizable payment solution, enabling you to offer your customers a seamless and unique shopping experience."
              )}
            </h2>
          </div>
          <div style={{ padding: "0 200px" }} className="column">
            <PhoneDevice
              className="is-perspective is-phone-device"
              bgFluid={imagesFluid[0]}
            />
            <PhoneDevice
              className="is-perspective is-phone-device less-margin"
              bgFluid={imagesFluid[1]}
              isLeft
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const SecureSection = () => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <ResponsiveIcon />,
      title: t("Flawless on Every Device"),
      description: t(
        "The payment solution renders seamlessly on desktops, Android and iOS devices"
      ),
    },
    {
      icon: <BubblesIcon />,
      title: t("Frictionless User Experience"),
      description: t(
        "Your logo, your fonts and brand colors, your one-of-a-kind user experience (UX)"
      ),
    },
    {
      icon: <OneTouchIcon />,
      title: t("One-Touch Payments"),
      description: t("Instant purchases, with no need to reenter card details"),
    },
  ]
  return (
    <section className="section has-margin-bottom-6">
      <div className="container">
        <SectionTitle
          title={t("Tailor-Made Mobile Payments")}
          subtitle={t(
            "Quick and easy to install, Twispay integrates seamlessly into your website's flows and designs"
          )}
        />
        <div className="columns has-text-centered ">
          {items.map(item => (
            <div key={item.title} className="column">
              <span className="icon is-larger has-margin-bottom-5 has-text-primary">
                {item.icon}
              </span>
              <h4 className="title is-4 ">{item.title}</h4>
              <p className="subtitle is-5 has-text-secondary">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const MobileSection = ({ imageFluid }) => {
  const { isMobile } = useResponsive()
  const { t } = useTranslation()
  const items = {
    left: [
      {
        icon: <ReloadIcon />,
        title: t("Automated Subscriptions"),
        subtitle: t(
          "Straightforward recurring payments and service subscriptions"
        ),
      },
      {
        icon: <SafeIcon />,
        title: t("Safe & Secure"),
        subtitle: t("PCI-DSS compliant. 24/7 fraud monitoring"),
      },
    ],
    right: [
      {
        icon: <PaintIcon />,
        title: t("Tailored Checkout"),
        subtitle: t(
          "Your checkout page will match the look and feel of your brand"
        ),
      },
      {
        icon: <BubblesIcon />,
        title: t("Quality Focused"),
        subtitle: t(
          "Seamless UX and continuous conversion rate optimization (CRO)"
        ),
      },
    ],
  }
  return (
    <section className="section mobile-section has-margin-bottom-6">
      <div className="container">
        <div className="columns ">
          <div className="column has-text-right has-text-centered-mobile is-left">
            <h3 className="title is-spaced">
              {t("The One-Stop-Shop for Online Payments")}
            </h3>
            <p className="subtitle">
              {t(
                "We have built a completely compliant payment gateway to provide you with a reliable and secure one-stop-shop for online transactions"
              )}
            </p>
            {items.left.map((item, i) => (
              <div
                key={item.title}
                className={`feature-item is-flex-tablet ${
                  i === 1 ? "" : "has-margin-bottom-5"
                }`}
              >
                <span
                  style={isMobile ? {} : { order: 1 }}
                  className="icon is-medium has-text-primary has-margin-left-4"
                >
                  {item.icon}
                </span>
                <div className="text">
                  <h4 className="title is-5">{item.title}</h4>
                  <p className="subtitle is-6 has-text-secondary">
                    {item.subtitle}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div
            className={`column ${
              isMobile ? "has-margin-top-5 has-margin-bottom-5" : ""
            }`}
          >
            <PhoneDevice isCenter bgFluid={imageFluid} />
          </div>
          <div className="column has-text-centered-mobile">
            <h3 className="title is-spaced">
              {t("Your Custom Checkout Page")}
            </h3>
            <p className="subtitle ">
              {t(
                "This is where the power of a flexible system meets the beauty of a completely personalised user experience (UX)"
              )}
            </p>
            {items.right.map((item, i) => (
              <div
                key={item.title}
                className={`feature-item is-flex-tablet ${
                  i === 1 ? "" : "has-margin-bottom-5"
                }`}
              >
                <span className="icon is-medium has-text-primary has-margin-right-4">
                  {item.icon}
                </span>
                <div className="text">
                  <h4 className="title is-5">{item.title}</h4>
                  <p className="subtitle is-6 has-text-secondary">
                    {item.subtitle}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

const ChartSection = () => {
  const { t } = useTranslation()
  const years = {
    2015: {
      percentage: 49.3,
      height: "38%",
    },
    2016: {
      percentage: 52.4,
      height: "50%",
    },
    2017: {
      percentage: 58.9,
      height: "64%",
    },
    2018: {
      percentage: 63.5,
      height: "80%",
    },
    2019: {
      percentage: 67.2,
      height: "88%",
    },
  }
  const activeYear = "2018"
  return (
    <section className="section has-margin-bottom-6">
      <div className="container">
        <SectionTitle
          title={t("Mobile Purchases Are on the Rise")}
          subtitle={t(
            "Twispay offers an extended array of payment modules for the most popular ecommerce platforms"
          )}
        />
        <div className="columns">
          <div className="column">
            <Chart bars={years} activeBar={activeYear}>
              {Object.keys(years).map(year => (
                <span
                  className={`${
                    activeYear === year
                      ? "has-text-primary"
                      : "has-text-secondary"
                  } has-text-weight-bold`}
                  key={year}
                >
                  {year}
                </span>
              ))}
            </Chart>
          </div>
          <div className="column is-one-third">
            <h4 className="is-size-4">{t("Mobile Shopping Trends")}</h4>
            <p className="is-size-5 has-text-secondary has-margin-bottom-4">
              {t(
                "Mobile eCommerce (aka m-commerce) shows extraordinary potential for further growth."
              )}
              <br />
              <br />
              {t(
                "eCommerce growth worldwide is being driven by shoppers using their mobile devices to acquire goods and services. According to eMarketer estimates, eCommerce sales reached $2.3 trillion in 2017, a 23.2 percent increase over the previous year."
              )}
              <br />
              <br />
              {t(
                "The mobile share of this stood at 58.9 percent, or $1.4 trillion. In 2021, mobile eCommerce is expected to break the $3.5 trillion mark and then make up almost three quarters (72.9 percent of eCommerce sales."
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const MobilePaymentPage = ({ data: images }) => {
  const { t } = useTranslation()
  return (
    <Layout
      stripeClass="is-blue-violet"
      pageName="mobile-payments-service-provider"
    >
      <SEO title={t("Mobile Payments Services")} />
      <HeroSection
        imagesFluid={[
          images.hero1.childImageSharp.fluid,
          images.hero2.childImageSharp.fluid,
        ]}
      />
      <SecureSection />
      <MobileSection imageFluid={images.mobileCenter.childImageSharp.fluid} />
      <ChartSection />
      <GetStartedSection
        title={t("More than a Payment Service Provider")}
        subtitle={t("Fine-tune your online shopping experience with Twispay")}
      />
    </Layout>
  )
}

export const images = graphql`
  query {
    hero1: file(relativePath: { eq: "mobile-provider-hero-1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    hero2: file(relativePath: { eq: "mobile-provider-hero-2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileCenter: file(
      relativePath: { eq: "mobile-provider-phone-center.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default MobilePaymentPage
