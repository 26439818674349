import React from "react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"

import "../styles/components/phone-device.scss"

const PhoneDevice = ({ children, isLeft, isCenter, bgFluid, ...props }) => {
  return (
    <div {...props}>
      <div className={`phone-device-wrapper ${isLeft ? "is-left" : ""} ${isCenter ? "is-center" : ""}`} >
        <BackgroundImage Tag="div" className="display-wrapper" fluid={bgFluid}>
          {children}
        </BackgroundImage>
      </div>
    </div>
  )
}

PhoneDevice.propTypes = {
  children: PropTypes.node,
  isPerspective: PropTypes.bool,
  isLeft: PropTypes.bool,
  isCenter: PropTypes.bool,
  bgFluid: PropTypes.object,
}

export default PhoneDevice
